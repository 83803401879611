<template>
  <div :class="$style.container">
    <div :class="$style.header">
      <router-link
        :to="
          getRoute({
            route: $options.MANAGERS_ROUTES.CREATE,
          })
        "
        :class="$style.create"
      >
        <adw-icon name="plus" :class="$style.icon" /> Создать менеджера
      </router-link>
      <Autocomplete
        :class="$style.search"
        :search="filters.search"
        placeholder="Поиск по email и имени менеджера"
        :valueNames="['name', 'email']"
        @querySearch="querySearchManagers"
        @selectItem="handleSelectManager"
        @handleFindItems="getManagers"
        @input="filters.search = $event"
      />
      <div :class="$style.filters">
        <adw-button @click="isOpenDropDown = !isOpenDropDown"
          >Фильтры</adw-button
        >
        <Dropdown
          :isOpenDropDown="isOpenDropDown"
          title="Фильтры дилера"
          :class="$style.dropdown"
          @submitFilters="handleSubmitFilters"
          @resetFilters="handleResetFilters"
        >
          <div :class="$style.filter">
            <p>Роль:</p>
            <el-select
              v-model="filters.roles"
              filterable
              clearable
              remote
              multiple
              placeholder="Выбрать"
            >
              <el-option
                v-for="(item, index) in $options.ROLES"
                :key="index + item"
                :label="$options.TRANSLATED_ROLES[item]"
                :value="item"
              >
                <span>{{ $options.TRANSLATED_ROLES[item] }}</span>
              </el-option>
            </el-select>
          </div>
          <div :class="$style.filter">
            <p>Доп. роль:</p>
            <el-select
              v-model="filters.additionalRoles"
              filterable
              clearable
              remote
              multiple
              placeholder="Выбрать"
            >
              <el-option
                v-for="(item, index) in $options.ADDITIONAL_ROLES"
                :key="index + item"
                :label="$options.TRANSLATED_ADDITIONAL_ROLES[item]"
                :value="item"
              >
                <span>{{ $options.TRANSLATED_ADDITIONAL_ROLES[item] }}</span>
              </el-option>
            </el-select>
          </div>
          <div :class="$style.filter">
            <p>Доступ:</p>
            <el-select
              v-model="filters.projectPermissions"
              filterable
              clearable
              remote
              multiple
              placeholder="Выбрать"
            >
              <el-option
                v-for="(item, index) in Object.keys($options.PROJECTS)"
                :key="index + item"
                :label="$options.PROJECTS_TRANSLATED[item]"
                :value="$options.PROJECTS[item]"
              >
                <span>{{ $options.PROJECTS_TRANSLATED[item] }}</span>
              </el-option>
            </el-select>
          </div>
          <div :class="$style.filter">
            <p>Доступ при регистрации:</p>
            <el-select
              v-model="filters.availableUponRegistration"
              filterable
              clearable
              remote
              placeholder="Выбрать"
            >
              <el-option
                v-for="(item, index) in $options.DEALERS_BOOLEANS"
                :key="index"
                :label="item.name"
                :value="item.value"
              >
                <span>{{ item.name }}</span>
              </el-option>
            </el-select>
          </div>
        </Dropdown>
      </div>
    </div>
    <el-table :data="managers" stripe>
      <el-table-column label="Фото">
        <template slot-scope="scope">
          <img
            v-if="scope.row.image"
            :src="$configData.s3_link + scope.row.image"
            alt=""
            width="50"
            height="50"
          />
          <img
            v-else
            src="/img/createManagers/default-avatar.webp"
            alt="Фото"
            width="50"
            height="50"
          />
        </template>
      </el-table-column>
      <el-table-column label="Имя" prop="name"> </el-table-column>
      <el-table-column label="Email" prop="email"> </el-table-column>
      <el-table-column label="Номер телефона" prop="phone"></el-table-column>
      <el-table-column
        label="Доп. Номер телефона"
        prop="additionalPhone"
      ></el-table-column>
      <el-table-column label="Роль">
        <template slot-scope="scope">
          <span>{{ $options.TRANSLATED_ROLES[scope.row.role] }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Доп. роль">
        <template slot-scope="scope">
          <span>{{ getAdditionalRolesString(scope.row.additionalRoles) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Доступ к Landing">
        <template slot-scope="scope">
          <el-checkbox
            :class="$style.readOnly"
            :value="
              hasAccess(
                scope?.row?.projectPermission,
                $options.PROJECTS.ADDLANDINGS,
              )
            "
          />
        </template>
      </el-table-column>
      <el-table-column label="Доступ к Sellers">
        <template slot-scope="scope">
          <el-checkbox
            :class="$style.readOnly"
            :value="
              hasAccess(
                scope?.row?.projectPermission,
                $options.PROJECTS.ADDSELLERS,
              )
            "
          />
        </template>
      </el-table-column>
      <el-table-column label="Доступ к Addwine">
        <template slot-scope="scope">
          <el-checkbox
            :class="$style.readOnly"
            :value="
              hasAccess(
                scope?.row?.projectPermission,
                $options.PROJECTS.ADDWINE,
              )
            "
          />
        </template>
      </el-table-column>
      <el-table-column label="Доступ к Addevent">
        <template slot-scope="scope">
          <el-checkbox
            :class="$style.readOnly"
            :value="
              hasAccess(
                scope?.row?.projectPermission,
                $options.PROJECTS.ADDEVENTS,
              )
            "
          />
        </template>
      </el-table-column>
      <el-table-column label="Доступен при регистрации">
        <template slot-scope="scope">
          <el-checkbox
            :class="$style.readOnly"
            :value="scope?.row?.availableUponRegistration"
          />
        </template>
      </el-table-column>
      <el-table-column label="Тег менеджера" prop="managerTag">
      </el-table-column>
      <el-table-column label="Тег активен">
        <template slot-scope="scope">
          <el-checkbox
            :class="$style.readOnly"
            :value="scope?.row?.managerTagEnabled"
          />
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <div :class="$style.actionButtons">
            <adw-social-icon
              name="whatsAppLogo"
              :class="$style.icon"
              :link="getWhatsUpLink(scope?.row?.phone)"
            />
            <ActionButtons
              :editLink="
                getRoute({
                  route: $options.MANAGERS_ROUTES.UPDATE,
                  params: { id: scope?.row?.id },
                })
              "
              @delete="deleteManager(scope?.row)"
            />
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="totalPages > 1"
      layout="prev, pager, next"
      :page-size="limit"
      :total="count"
      :current-page.sync="page"
      background
      :class="$style.pagination"
      @current-change="getManagers"
    ></el-pagination>
  </div>
</template>

<script>
import delivery from '@/delivery'
import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import Dropdown from '@/components/atoms/Dropdown'
import Autocomplete from '@/components/atoms/Autocomplete'
import notifications from '@/mixins/notifications'

import {
  ROLES,
  TRANSLATED_ROLES,
  ADDITIONAL_ROLES,
  TRANSLATED_ADDITIONAL_ROLES,
} from '@/constants/roles'
import { PROJECTS, PROJECTS_TRANSLATED } from '@/constants/projects'
import { MANAGERS_ROUTES, getRoute } from '@/constants/routing'
import { DEALERS_BOOLEANS } from '@/constants/dealers'

export default {
  DEALERS_BOOLEANS,
  PROJECTS,
  PROJECTS_TRANSLATED,
  ROLES,
  TRANSLATED_ROLES,
  ADDITIONAL_ROLES,
  TRANSLATED_ADDITIONAL_ROLES,
  MANAGERS_ROUTES,
  components: {
    ActionButtons,
    Dropdown,
    Autocomplete,
  },
  mixins: [notifications],
  mounted() {
    this.getManagers()
  },
  data() {
    return {
      isOpenDropDown: false,
      filters: {
        roles: null,
        search: null,
        additionalRoles: null,
        projectPermissions: null,
        availableUponRegistration: null,
      },
      managers: [],
      page: 1,
      limit: 10,
      count: 0,
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.count / this.limit)
    },
  },
  methods: {
    getWhatsUpLink(phone) {
      return (
        'https://api.whatsapp.com/send/?phone=' + phone?.replaceAll('+', '')
      )
    },
    hasAccess(permissions, project) {
      return permissions?.includes(project)
    },
    getAdditionalRolesString(roles) {
      return (
        roles?.map((e) => TRANSLATED_ADDITIONAL_ROLES[e])?.join(',\n') ?? ''
      )
    },
    handleSelectManager(selectedManager) {
      this.$router.push(
        this.getRoute({
          route: MANAGERS_ROUTES.UPDATE,
          params: { id: selectedManager?.id },
        }),
      )
    },
    async querySearchManagers({ queryString, setSearchItems }) {
      const query = {
        limit: 10,
        search: queryString,
      }
      const loading = this.$loading({
        lock: true,
      })
      const { value, error } =
        await delivery.AddwineCore.ManagersActions.getList(query)
      loading.close()
      if (error) return
      setSearchItems(value.data)
    },
    async getManagers() {
      const params = {
        page: this.page,
        limit: this.limit,
        search: this.filters.search,
        roles: this.filters.roles,
        additionalRoles: this.filters.additionalRoles,
        projectPermissions: this.filters.projectPermissions,
        availableUponRegistration: this.filters.availableUponRegistration,
      }
      const loading = this.$loading({
        lock: true,
      })
      const { value, error } =
        await delivery.AddwineCore.ManagersActions.getList(params)
      loading.close()
      if (error) return
      this.managers = value.data
      this.count = value.meta.count
    },
    async deleteManager(manager) {
      if (
        confirm(
          `Вы действительно хотите навсегда удалить менеджера "${manager?.name}"?`,
        )
      ) {
        const loading = this.$loading({
          lock: true,
        })
        const result = await delivery.AddwineCore.ManagersActions.delete(
          manager?.id,
        )
        loading.close()

        if (result.error) {
          this.showNotification('Ошибка удаления менеджера', 'error')
          return
        }
        this.getManagers()
      }
    },
    async handleResetFilters() {
      this.filters = {
        roles: null,
        search: null,
      }
      this.isOpenDropDown = false

      await this.getManagers()
    },
    async handleSubmitFilters() {
      this.currentPage = 1

      this.isOpenDropDown = false

      await this.getManagers()
    },
    getRoute({ route, params }) {
      return getRoute({ route, params })
    },
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 1rem;
  .readOnly {
    pointer-events: none;
  }

  .header {
    position: sticky;
    top: 3rem;
    display: flex;
    gap: 1rem;
    padding: 1rem 0;
    z-index: $z-index-sticky;
    background-color: $smoky;
    .create {
      display: inline-flex;
      align-items: center;
      padding: 0.5rem 1rem;
      border: 0.0625rem solid $light-gray;
      gap: 0.5rem;
      text-decoration: none;
      color: $dark-gray;
      &:hover {
        background: $white;
      }
    }

    .search {
      &,
      & > div,
      & div > input {
        height: 3.1rem;
      }
    }

    .filters {
      position: relative;
      .dropdown {
        left: 7rem;
        top: 0;
        .filter {
          margin-top: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            max-width: 8rem;
            width: 100%;
            margin-right: 1rem;
          }
        }
      }
    }
  }
  .actionButtons {
    display: flex;
    padding: 0 1rem 0 0;
    .icon {
      margin-right: 0.9375rem;
    }
  }
  h2 {
    padding: 1rem 0;
  }
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
}
</style>
